import React from 'react';
import { graphql } from 'gatsby';
import {
  ItemsListIndexPage,
  PageHero,
  PageWithSubNav,
  Section,
} from 'docComponents';
import pageHeroData from '../../../data/pages/components.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Selects Overview"
      designCode
      subnav="components">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Selects"
        tierThree="Overview"
      />

      <Section>
        <ItemsListIndexPage
          data={data}
          tierTwo="selects"
          listType="visual"
          listLayout="3"
        />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query selectsDesignOverviewQuery($tierOne: String = "Components") {
    ...indexList
  }
`;
